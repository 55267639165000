import { useLabsStore } from "../stores/LabsStore"
import { useOrganismStore } from "../stores/OrganismStore"
import { PipelineTemplate } from "../types/LabTypes"
import { Sample } from "../types/SampleTypes"
var _ = require("lodash")

interface StartPipelineOnSelectedFunctions {
  getSampleMappings: (samples: Sample[], selectedTemplate: PipelineTemplate) => {
    [key: string]: any;
  }[]
  sampleMetadataTemplateIsValid: (sampleMetadataMapping: {
    [key: string]: any;
  }) => boolean
  allsampleMetadataTemplatesAreValid: (samples: Sample[], selectedTemplateName: string) => boolean
}

export const useStartPipelineOnSelectedFunctions = (): StartPipelineOnSelectedFunctions => {
  const { organism } = useOrganismStore()
  const { userLab } = useLabsStore();

  const getSampleMappings = (samples: Sample[], selectedTemplate: PipelineTemplate) => {
    return samples.map(sample => {
      return selectedTemplate?.fields.reduce((pv, field) => {
        if (field.condition) {
          if (field.condition.op === "eq" && sample.data.metadata[field.condition.field] !== field.condition.value)
            return pv
          if (field.condition.op === "ne" && sample.data.metadata[field.condition.field] === field.condition.value)
            return pv
        }
        if (field.fieldType === "mapping")
          pv[field.fieldName] = field.fieldValue.split(";").map(sampleFieldName => {
            const property = organism?.properties[sampleFieldName]
            if (!property?.path) {
              return ""
            }
            const value = _.get(sample, property.path)?.toString() as string ?? ""
            if (organism?.properties[sampleFieldName].type === "date") {
              if (isNaN(Date.parse(value)))
                return ""
              let date2 = new Date(value ?? "")
              if (field.dateFormat === "YYYY")
                return date2.getFullYear()
              else
                return `${date2.getFullYear()}-${date2.getMonth()?.toString().padStart(2, "0")}`
            }
            return value ?? ""
          }).join(" ").trim();
        if (field.fieldType === "value")
          pv[field.fieldName] = field.fieldValue
        return pv
      }, {} as { [key: string]: any }) ?? {}
    })
  }

  const sampleMetadataTemplateIsValid = (sampleMetadataMapping: { [key: string]: any; }) => {
    return Object.entries(sampleMetadataMapping).filter(([, v]) => !v).length === 0
  }

  const allsampleMetadataTemplatesAreValid = (samples: Sample[], selectedTemplateName: string) => { 
    const template = userLab?.pipelineTemplates.find(v => v.name === selectedTemplateName)   
    if (template)
      return getSampleMappings(samples, template)
        .map(sampleMetadataMapping => sampleMetadataTemplateIsValid(sampleMetadataMapping)) //array of 'are all object values truthy'
        .filter(allTruthy => !allTruthy).length === 0 //no samples are missing a mapping value
    else
      return true
  }


  return { getSampleMappings, sampleMetadataTemplateIsValid, allsampleMetadataTemplatesAreValid }
}